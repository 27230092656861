import { Classification } from "./../layout/models/classification";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { PagedQuery } from "../layout/models/pagedQuery";

export default class ClassificationStore {
  classifications: Classification[] | null = null;
  totalCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getClassifications = async (
    query: PagedQuery,
    classificationCriteriaId: number
  ) => {
    try {
      const classifications = await agent.Classifications.paged(
        query,
        classificationCriteriaId
      );
      runInAction(() => {
        this.classifications = classifications.data;
        this.totalCount = classifications.totalCount;
      });
    } catch (error) {
      throw error;
    }
  };
}
