import { createContext, useContext } from "react";
import CategoryStore from "./categoryStore";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import OrganizationStore from "./organizationStore";
import TemplateStore from "./templateStore";
import DashboardAdminStore from "./dashboardAdminStore";
import DashboardAllSurveysStore from "./dashboardAllSurveysStore";
import UIStore from "./uiStore";
import SurveyModelStore from "./surveyModelStore";
import ClassificationStore from "./classificationStore";

interface Store {
  categoryStore: CategoryStore;
  userStore: UserStore;
  commonStore: CommonStore;
  organizationStore: OrganizationStore;
  templateStore: TemplateStore;
  dashboardAdminStore: DashboardAdminStore;
  dashboardAllSurveysStore: DashboardAllSurveysStore;
  uiStore: UIStore;
  classificationStore: ClassificationStore;
  //   surveyModelStore: SurveyModelStore;
  surveyStore: SurveyModelStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  categoryStore: new CategoryStore(),
  userStore: new UserStore(),
  organizationStore: new OrganizationStore(),
  templateStore: new TemplateStore(),
  dashboardAdminStore: new DashboardAdminStore(),
  dashboardAllSurveysStore: new DashboardAllSurveysStore(),
  uiStore: new UIStore(),
  // surveyModelStore: new SurveyModelStore(),
  surveyStore: new SurveyModelStore(),
  classificationStore: new ClassificationStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
