import NavBar from "./NavBar";
import { Route, Switch } from "react-router-dom";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import { Container, makeStyles, Box } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import UserMenuMobile from "../components/UserMenuMobile";
import { useState, useEffect, Suspense, lazy } from "react";
import { ApplicationPaths } from "../authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "../authorization/ApiAuthorizationRoutes";
import AuthorizeRoute from "../authorization/AuthorizeRoute";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../components/Loader/Loader";
import CookieWarning from "../components/CookieWarining/CookieWarning";

import { Roles, Routes } from "../utils/constants";
import SelectSurveyTemplateFrom from "../components/Surveys/SelectSurveyTemplateForm";

const useStyles = makeStyles((theme) => ({
  backgroundPattern: {
    backgroundColor: "#EFEEEE",
    backgroundBlendMode: "hard-light",
    display: "flex",
    flexFlow: "column",
  },
  AppContainer: {
    flex: 1,
    backgroundColor: "#EFEEEE",
    backgroundBlendMode: "hard-light",
    padding: "0 8rem 2rem 8rem",
    maxWidth: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
  BoxContainer: {},
}));

const Login = lazy(() => import("../components/LoginRegister/LoginForm"));
const PasswordReset = lazy(() => import("../layout/pages/PasswordReset"));
const SetNewPassword = lazy(() => import("../layout/pages/SetNewPassword"));
const Categories = lazy(() => import("../../app/layout/pages/Categories"));
const CategoryDetails = lazy(
  () => import("../components/Categories/CategoryDetails")
);
const CreateCategoryForm = lazy(
  () => import("../components/Categories/CreateCategoryForm")
);
const CreateQuestionForm = lazy(
  () => import("../components/Questions/CreateQuestionForm")
);
const InvitationForm = lazy(
  () => import("../components/LoginRegister/InvitationForm")
);
const CreateOrganizationForm = lazy(
  () => import("../components/Organizations/CreateOrganizationForm")
);
const SurveyCreation = lazy(() => import("./pages/SurveyCreation"));
const OrganizationDetails = lazy(
  () => import("../components/Organizations/OrganizationDetails")
);
const InvitationRegistrationForm = lazy(
  () => import("../components/LoginRegister/InvitationRegistrationForm")
);
const UploadQuestionForm = lazy(
  () => import("../components/Categories/UploadQuestionsForm")
);
const Organizations = lazy(
  () => import("../../app/layout/pages/Organizations")
);
const EditOrganizationForm = lazy(
  () => import("../components/Organizations/EditOrganizationForm")
);
const Templates = lazy(() => import("../../app/layout/pages/Templates"));
const EditTemplateForm = lazy(
  () => import("../components/Templates/EditTemplateForm")
);
const Classifications = lazy(
  () => import("../../app/layout/pages/Classifications")
);
const CreateTemplateForm = lazy(
  () => import("../components/Templates/CreateTemplateForm")
);
const DashboardAdmin = lazy(
  () => import("../../app/layout/pages/DashboardAdmin")
);
const DashboardAllSurveys = lazy(
  () => import("../../app/layout/pages/DashboardAllSurveys")
);
const Reports = lazy(() => import("../../app/layout/pages/Reports"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Home = lazy(() => import("./pages/Home"));
const SurveyInvitation = lazy(() => import("../layout/pages/SurveyInvitation"));
const StartByCode = lazy(
  () => import("../components/LoginRegister/StartByCode")
);
const Legal = lazy(() => import("../layout/pages/Legal"));
const SurveyModelCreation = lazy(() => import("./pages/SurveyModelCreation"));
const SurveyModels = lazy(() => import("../../app/layout/pages/SurveyModels"));
const OrganizationItem = lazy(
  () => import("../components/Organizations/OrganizationItem")
);

function App() {
  const style = useStyles();
  const { userStore, uiStore } = useStore();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const userMenuMobileView = useMediaQuery("(max-width: 427px)");

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    uiStore.setLoadingState(true);

    const initUser = async () => {
      await userStore.intializeUser();
    };

    initUser();
    uiStore.setLoadingState(false);
  }, [userStore, uiStore]);

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    uiStore.setUIState("", false);
  };

  return (
    <div className={style.backgroundPattern}>
      <NavBar openMenu={openMenu} setOpenMenu={setOpenMenu} />

      {uiStore.getLoading() ? <Loader /> : null}

      {openMenu && userMenuMobileView && <UserMenuMobile />}

      <Container className={style.AppContainer}>
        <Box className={style.BoxContainer}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path={Routes.login}>
                <Login />
              </Route>
              <Route path={Routes.reset}>
                <PasswordReset />
              </Route>
              <Route path={Routes.newPassword}>
                <SetNewPassword />
              </Route>
              <AuthorizeRoute
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.categories}
                component={Categories}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.createCategory}
                component={CreateCategoryForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.categoryId}
                component={CategoryDetails}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.categoryIdCreateQuestion}
                component={CreateQuestionForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.organizationIdInvitation}
                component={InvitationForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.createOrganization}
                component={CreateOrganizationForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner, Roles.user]}
                userRole={userStore.user?.role}
                path={Routes.createSurvey}
                component={SurveyCreation}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.surveymodels}
                component={SurveyModels}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner, Roles.user]}
                userRole={userStore.user?.role}
                path={Routes.createsurveymodel}
                component={SurveyModelCreation}
              ></AuthorizeRoute>
              <Route exact path={Routes.surveyAccessCode}>
                <SurveyInvitation />
              </Route>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner, Roles.user]}
                userRole={userStore.user?.role}
                path={Routes.organization}
                component={OrganizationDetails}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner, Roles.user]}
                userRole={userStore.user?.role}
                path={Routes.organizationsurveys}
                component={OrganizationItem}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.invitationInvitationId}
                component={InvitationRegistrationForm}
              ></AuthorizeRoute>
              <Route exact path={Routes.registerInvitationId}>
                <InvitationRegistrationForm />
              </Route>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.import}
                component={UploadQuestionForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.organizations}
                component={Organizations}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.organizationsOrgId}
                component={EditOrganizationForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.templates}
                component={Templates}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.templatesOrgId}
                component={EditTemplateForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.fantastics}
                component={Classifications}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.professionalDevelopment}
                component={Classifications}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.personalDevelopment}
                component={Classifications}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.culturalDevelopment}
                component={Classifications}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner]}
                userRole={userStore.user?.role}
                path={Routes.createTemplate}
                component={CreateTemplateForm}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner, Roles.user]}
                userRole={userStore.user?.role}
                path={Routes.dashboardAdmin}
                component={DashboardAdmin}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin, Roles.owner, Roles.user]}
                userRole={userStore.user?.role}
                path={Routes.dashboardAdminOrgId}
                component={Reports}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.dashboardAllSurveys}
                component={DashboardAllSurveys}
              ></AuthorizeRoute>
              <AuthorizeRoute
                exact
                roles={[Roles.admin]}
                userRole={userStore.user?.role}
                path={Routes.dashboardAllSurveysOrgId}
                component={Reports}
              ></AuthorizeRoute>
              <Route
                exact
                path={Routes.reportResults}
                component={Reports}
              ></Route>
              <Route exact path={Routes.code} component={StartByCode}></Route>
              <Route exact path={Routes.legal} component={Legal}></Route>
              {userStore.isLoggedIn ? (
                <Route exact path={Routes.home}>
                  <Dashboard />
                </Route>
              ) : (
                <Route exact path={Routes.home}>
                  <Home />
                </Route>
              )}

              {/* <Route component={NotFound} /> */}

              <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
                <ApiAuthorizationRoutes />
              </Route>
            </Switch>
          </Suspense>
        </Box>
        <Snackbar
          open={uiStore.getResponseMessage() ? true : false}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {uiStore.getResponseMessage()}
          </Alert>
        </Snackbar>
      </Container>
      <CookieWarning />
    </div>
  );
}

export default observer(App);
