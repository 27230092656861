import React, { FC } from 'react'
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    containerPattern: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        margin: '0 auto',
        left: '0',
        right: '0',
        top: '0',
        zIndex: 1101,
        background: 'hsla(0, 0%, 97%, 0.718)'
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        zIndex: 30
    },
    loadingText: {
        marginTop: '3vh'
    }
  });

const Loader: FC = React.memo(() => {
    const styles = useStyles();

    return (
        <Box className={styles.containerPattern} display="flex" justifyContent="center" mt={4}>
            <div className={styles.loading}>
                <CircularProgress />
                <Typography className={styles.loadingText}>Loading...</Typography>
            </div>
        </Box>
    )
})

export default Loader