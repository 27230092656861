import axios, { AxiosResponse } from "axios";
import { Category } from "../layout/models/category";
import {
  InvitationRegister,
  UserRegister,
} from "../layout/models/userRegister";
import { UserLogin } from "../layout/models/userLogin";
import { SetNewPass } from "../layout/models/setNewPass";
import { User } from "../layout/models/user";
import { CategoryCreate } from "../layout/models/categoryCreate";
import { Question } from "../layout/models/question";
import {
  Organization,
  OrganizationCreate,
  OrganizationEdit,
} from "../layout/models/organization";
import {
  Template,
  TemplateCreate,
  TemplateEdit,
} from "../layout/models/template";
import { Invitation, InvitationCreate } from "../layout/models/invitation";
import { SurveyTemplate } from "../layout/models/surveyTemplate";
import { SurveyAnswer } from "../layout/models/surveyAnswer";
import { TemplatePresentation } from "./../layout/models/TemplatePresentation";
import { SurveyCreate } from "./../layout/models/surveyCreate";
import { QuestionPresentation } from "../layout/models/questionPresentation";
import { ServerResponse } from "../layout/models/serverResponse";
import { PagedQuery } from "../layout/models/pagedQuery";
import { DashboardAdmin } from "../layout/models/dashboardAdmin";
import { DashboardAllSurveys } from "../layout/models/dashboardAllSurveys";
import { SurveyQuestion } from "../layout/models/surveyQuestion";
import CheckResult from "../layout/models/checkResult";
import {
  SurveyModelCreate,
  SurveyModel,
  SurveyModelEdit,
} from "./../layout/models/surveyModel";
import {
  Classification,
  ClassificationEdit,
} from "../layout/models/classification";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const pagedResponseBody = <T>(response: ServerResponse<T>) => {
  return { ...response };
};

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  patch: <T>(url: string, body: {}) =>
    axios.patch<T>(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  paged: <T>(url: string, body: {}) =>
    axios.post<T, ServerResponse<T>>(url, body).then(pagedResponseBody),
};

const Categories = {
  list: () => requests.get<Category[]>("/Category"),
  details: (id: string) => requests.get<Category>(`/category/${id}`),
  create: (category: CategoryCreate) =>
    requests.post<string>("/Category", category),
  patch: (category: Category) => requests.patch<void>(`/Category`, category),
  delete: (id: string) => requests.delete(`/Category/${id}`),
  paged: (query: PagedQuery) =>
    requests.paged<Category[]>("Category/query", query),
};

const Account = {
  register: (credentials: UserRegister) =>
    requests.post<UserRegister>("Account/register", credentials),
  registerInvitation: (credentials: InvitationRegister) =>
    requests.post<UserRegister>("Account/register", credentials),
  userInvitationRegistration: (user: {}) =>
    requests.post("User/unique/username", user),
  login: (credentials: UserLogin) =>
    requests.post<User>("Account/login", credentials),
  current: () => requests.get<User>("Account"),
  resetPassword: (email: {}) => requests.post("Account/reset-token", email),
  setNewPassword: (newCredentials: SetNewPass) =>
    requests.post("/Account/reset-password", newCredentials),
};

const Questions = {
  create: (question: Question) => requests.post<string>("Question", question),
  listByCategory: (id: string) =>
    requests.get<QuestionPresentation[]>(`Question/category/${id}`),
  delete: (id: string) => requests.delete(`Question/${id}`),
  update: (id: string, question: Question) =>
    requests.patch<string>(`Question?id=${id}`, question),
};

const Organizations = {
  info: (id: string) => requests.get<Organization>(`Organization/${id}`),
  details: () => requests.get<Organization>("Organization"),
  create: (organization: OrganizationCreate) =>
    requests.post<string>("Organization", organization),
  paged: (query: PagedQuery) =>
    requests.paged<Organization[]>("Organization/query", query),
  update: (organization: OrganizationEdit) =>
    requests.patch<void>("Organization", organization),
  deleteUser: (id: string) => requests.delete(`/Organization/user/${id}`),
  delete: (id: string) => requests.delete(`/Organization/${id}`),
};

const Templates = {
  info: (id: string) => requests.get<Template>(`Template/${id}`),
  details: () => requests.get<Template>("Template"),
  create: (template: TemplateCreate) =>
    requests.post<string>("Template", template),
  paged: (query: PagedQuery) =>
    requests.paged<Template[]>("Template/query", query),
  update: (template: TemplateEdit) =>
    requests.patch<void>("Template", template),
  delete: (id: string) => requests.delete(`/Template/${id}`),
};

const Classifications = {
  info: (id: number) => requests.get<Classification>(`Classification/${id}`),
  paged: (query: PagedQuery, classificationCriteriaId: number) =>
    requests.paged("Classification/query", {
      classificationCriteriaId,
      ...query,
    }),
  update: (classification: ClassificationEdit) =>
    requests.patch<void>("Classification", classification),
};

const Invitations = {
  create: (invitation: InvitationCreate) =>
    requests.post<string>("Invitation", invitation),
  details: (id: string) =>
    requests.post<Invitation>(`Invitation/get`, { code: id }),
};

const SurveyTemplates = {
  create: (surveyTemplate: SurveyTemplate) =>
    requests.post<string>("Template", surveyTemplate),
  list: () => requests.get<TemplatePresentation[]>("Template"),
};

const Surveys = {
  info: (code: string) => requests.get(`Survey/${code}`),
  create: (survey: SurveyCreate) => requests.post<string>("Survey", survey),
  paged: (query: PagedQuery) =>
    requests.paged<DashboardAdmin[]>("Survey/query", query),
  pagedAll: (query: PagedQuery) =>
    requests.paged<DashboardAllSurveys[]>("Survey/queryall", query),
  invite: (url: string, body: {}) => requests.post(url, body),
  surveyQuestion: (accessCode: string) =>
    requests.get<SurveyQuestion>(`Survey/${accessCode}`),
  submitAnswers: (body: SurveyAnswer) => requests.post("Survey/answer", body),
  view: (body: { surveyId: number }) => requests.post("Survey/view", body),
  check: (body: { code: string }) =>
    requests.post<CheckResult>("Survey/check", body),
  delete: (surveyId: number) => requests.delete(`Survey/${surveyId}`),
  mail: (body: { surveyId: number }) => requests.post("Survey/mail", body),
  mailByCode: (body: {
    surveyCode: string;
    attachment: string;
    destinationEmails: string[];
  }) => requests.post("Survey/mailByCode", body),
};

const Files = {
  upload: (body: FormData) => requests.post<any>("Upload", body),
};

const Reports = {
  info: (id: string) => requests.get(`Report/${id}`),
  categories: (id: string) => requests.get(`Report/categories/${id}`),
  detailed: (id: string) => requests.get(`Report/detailed/${id}`),
  classifications: (id: string) => requests.get(`Report/classifications/${id}`),
};

const Role = {
  info: () => requests.get<{ role: string }>("Role"),
};

const SurveyModels = {
  info: (id: string) => requests.get<SurveyModel>(`SurveyModel/${id}`),
  create: (surveyModel: SurveyModelCreate) =>
    requests.post<string>("SurveyModel", surveyModel),
  paged: (query: PagedQuery) =>
    requests.paged<SurveyModel[]>("SurveyModel/query", query),
  delete: (id: string) => requests.delete(`/SurveyModel/${id}`),
  update: (surveyModel: SurveyModelEdit) =>
    requests.patch<void>("SurveyModel", surveyModel),
  listByOrganization: (id: string) =>
    requests.get<SurveyModel[]>(`SurveyModel/organization/${id}`),
  list: () => requests.get<SurveyModel[]>("/SurveyModel"),
};
const agent = {
  Categories,
  Account,
  Questions,
  Organizations,
  Templates,
  Invitations,
  SurveyTemplates,
  Surveys,
  Files,
  Reports,
  Role,
  SurveyModels,
  Classifications,
};

export default agent;
