import React from "react";
import ReactDOM from "react-dom";
import "./app/layout/styles.css";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store, StoreContext } from "./app/stores/store";
import axios, { AxiosError } from "axios";
import authService from "./app/authorization/AuthorizeService";
import "./index.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(async (config) => {
  store.uiStore.setUIState("", true);
  const token = await authService.getAccessToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  (response: any) => {
    store.uiStore.setUIState("", false);
    return response.data;
  },
  (error: AxiosError) => {
    const { data, status } = error.response!;
    store.uiStore.setUIState(error.message, false);

    return Promise.reject(error);
  }
);

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </StoreContext.Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
