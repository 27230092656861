import { UserLogin } from "../layout/models/userLogin";
import { User } from "../layout/models/user";
import agent from '../api/agent'
import { makeAutoObservable, runInAction } from "mobx";
import LoginBuilder from "../authorization/LoginBuilder";
import authService from "../authorization/AuthorizeService";

export default class UserStore {
    user: User | null = null;
    loginBuilder = new LoginBuilder();
    
    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn() {
        return !!this.user;
    }

    login = async (creds: UserLogin) => {
        try {
            await agent.Account.login(creds);
            await this.loginBuilder.login(this.loginBuilder.getReturnUrl());
        } catch (error) {
            throw error;
        }
    }

    intializeUser = async () => {
        if (await authService.isAuthenticated())
        {   
            const user = await authService.getUser();
            // const userRole = await agent.Role.info();

            runInAction(() => {
                this.user = user
                // this.user && (this.user.role = userRole.role)
            });
        }
    }

    logout = async () => {
        await authService.signOut();
        this.user = null;
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => this.user = user);
        } catch (error) {
            console.log(error);
        }
    }
}