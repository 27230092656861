import { makeAutoObservable, runInAction } from "mobx"

export default class UIStore {
    private responseMessage: string = '';
    private loading: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    setUIState = (message: string, loading: boolean) => {
        try{
            runInAction(() => {
                this.responseMessage = message;
                this.loading = loading;
            });
        } catch (error) {
            throw error;
        }
    }

    getLoading = () => this.loading
    setLoadingState = (state: boolean) => this.loading = state
    getResponseMessage = () => this.responseMessage
}