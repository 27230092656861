import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DashboardAdmin } from "../layout/models/dashboardAdmin";
import { PagedQuery } from "../layout/models/pagedQuery";
export default class DashboardAdminStore {
  surveys: DashboardAdmin[] | null = null;
  totalCount: number = 0;
  pageNumber: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getSurveys = async (query: PagedQuery) => {
    try {
      const surveys = await agent.Surveys.paged(query);
      runInAction(() => {
        this.surveys = surveys.data;
        this.totalCount = surveys.totalCount;
      });
    } catch (error) {
      throw error;
    }
  };

  setPage(pageNumber: number) {
    this.pageNumber = pageNumber;
  }
}
