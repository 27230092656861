import { TRANSLATIONS_FR } from "./fr/translations";
import { TRANSLATIONS_EN } from "./en/translations";

export const resources = {
    en: {
      translation: TRANSLATIONS_EN
    },
    fr: {
      translation: TRANSLATIONS_FR
    }
  };