export const TRANSLATIONS_EN = {
  description: {
    welcomeStory: "Login and start using Zebra.",
  },
  home: {
    about: `ZEBRA is a tool allowing to predict with a success rate of more than 95% the functioning of an individual in different professional situations.
        It is built from the measurement of innovative ITEMS and the analysis of crossed data never used before.
        We individuals at the disposal of companies anxious to set up their talent management operations but also to know better in order to better orient.`,
    corporate: `Corporate`,
    particular: "Particular",
  },
  dashboard: {
    templates: {
      title: "Templates",
      description:
        "Templates are sets of categories to highlight personality traits adapted to specific functions",
    },
    categories: {
      title: "Categories",
      description:
        "Categories are the underlying elements that allow the creation of tests or Templates",
    },
    organizations: {
      title: "Organizations",
      name: "Name",
      description: "Access management for organizations",
      createOrganization: {
        title: "Create an organization",
        nameLabel: "Organization name",
        surveyLimit: "Survey limit",
        date: "Date",
        endDate: "License end date",
        submit: "Submit",
        inviteButton: "Invite admin",
        createStep: "Create organization",
        inviteStep: "Send admin invitation",
      },
      editOrganization: {
        title: "Edit organization:",
        titleLabel: "Title",
        surveyLimit: "Survey limit",
        date: "License start date",
        endDate: "License end date",
        search: "Search...",
        save: "Save",
        cancel: "Cancel",
      },
      deleteButton: "Do you want to delete this organization?",
      yes: "Yes",
      no: "No",
    },
    myOrganization: {
      title: "My organization",
      description: "My license and the members of my organization",
    },
    mySurveys: {
      title: "My Surveys",
      description: "Tests created by me and my organization",
    },
    allSurveys: {
      title: "All surveys",
      description: "Tests of all organizations in the system",
    },
    allSurveyModels: {
      title: "All Survey Models",
      description: "This are the survey models for admin",
    },
    fantastics: {
      title: "The Fantastic Four",
      description: "These are the types of personalities",
    },
    professionalDevelopment: {
      title: "Professional Development",
      description: "These are the items that define professional development",
    },
    culturalDevelopment: {
      title: "Cultural Development",
      description: "These are the items that define cultural development",
    },
    personalDevelopment: {
      title: "Personal Development",
      description: "These are the items that define personal development",
    },
  },
  survey: {
    startMessage:
      "Choose the answer that spontaneously suits you the most. Otherwise, choose the one that is the least away from you. Have a good questionnaire!",
    startMessageTitle: "Before the survey",
    endMessage:
      "Thank you for taking this quiz. We will get back to you very quickly to share the results with you!",
    endMessageTitle: "Survey end",
    endMessageDescription: "You reached the end.",
  },
  surveyByCodePage: {
    inputMessage: "Enter your vode and start the survey",
  },
  templatesPage: {
    pageTitle: "Templates",
    columnTitle: "Template",
    createButton: "New template",
    createTemplate: {
      title: "Create a new template",
      templateName: "Template name",
      templateLabel: "Template",
      choices: "Choices",
      selected: "selected",
      submit: "Submit",
    },
    editTemplate: {
      title: "Edit template:",
      nameLabel: "Name",
      search: "Search...",
      save: "Save",
      cancel: "Cancel",
    },
    deleteButton: "Do you want to delete this template?",
    yes: "Yes",
    no: "No",
  },
  fantasticsPage: {
    title: "The Fantastic Four",
    columnTitle: "Name",
  },
  categoriesPage: {
    pageTitle: "Items",
    columnTitle: "Item",
    createButton: "Upload from file",
    editButton: "Create a new item",
    deleteButton: "Do you want to delete this item?",
    yes: "Yes",
    no: "No",
  },
  myOrganizationPage: {
    pageTitle: "My organization",
    licensesActive: "License active until:",
    surveyLimit: "Surveys left:",
    columnTitle: "Name",
    addUserButton: "Add user",
    inviteAdmin: {
      title: "Send admin invitation",
      createStep: "Create organization",
      inviteStep: "Send admin invitation",
      emailLabel: "Admin email",
      send: "Send",
    },
  },
  mySurveysPage: {
    pageTitle: "My surveys",
    columnTitle: "Title",
    participantColumn: "Participant",
    sentColumn: "Submitted date",
    completedColumn: "Finished date",
    linkColumn: "Link",
    unfinished: "Unfinished",
    statusColumn: "Status",
    completedStatus: "Completed",
    inprogressStatus: "In progress",
    sentStatus: "Submitted",
    createButton: "New survey",
    deleteButton: "Do you want to delete this survey?",
    filtersButton: "Filters",
    ascending: "Ascending",
    descending: "Descending",
    none: "None",
    applyFilters: "Apply Filters",
    clearFilters: "Clear Filters",
    close: "Close",
    yes: "Yes",
    no: "No",
    participantName: "Participant name",
    finishedDate: "Finished date",
    createSurvey: {
      title: "Create and send a survey",
      surveyTitle: "Survey title",
      participantName: "Participant name",
      participantEmail: "Participant email",
      search: "Search...",
      submit: "Submit",
    },
  },
  allSurveysPage: {
    pageTitle: "All surveys",
    columnTitle: "Titles",
    participantColumn: "Participant",
    sentColumn: "Submitted date",
    completedColumn: "Finished date",
    linkColumn: "Link",
    statusColumn: "Status",
    completedStatus: "Completed",
    inprogressStatus: "In progress",
    sentStatus: "Submitted",
    organisationColumn: "Organization",
  },
  organizationsPage: {
    pageTitle: "Organizations",
    columnTitle: "Organization name",
    surveyLeftColumn: "Surveys left",
    surveysCreatedColumn: "Surveys created",
    licenseEndDateColumn: "License end date",
    createButton: "New invitation",
  },
  global: {
    nextButton: "Next",
    continueButton: "Continue",
  },
  surveyModelPage: {
    pageTitle: "Survey models",
    columnTitle: "Survey model",
    createButton: "New survey model",
    createSurveyModel: {
      title: "Create a survey model",
      surveyTitle: "Survey title",
      search: "Search...",
      submit: "Submit",
    },
    editSurveyModel: {
      title: "Edit survey model:",
      nameLabel: "Name",
      search: "Search...",
      save: "Save",
      cancel: "Cancel",
    },
    deleteButton: "Do you want to delete this survey model?",
    yes: "Yes",
    no: "No",
  },
  reportPage: {
    key: "Key:",
    info: "A check (if correct) and a cross (if wrong) will appear next to the chosen answers.",
    correct: "Answered correctly",
    incorrect: "Answered incorrectly",
    missed: "Multiple choice option missed",
    advice: "Choose the best answer that suits you",
    goodAnswer: "Good answer:",
    yourAnswer: "You have chosen:",
    remark: "Remark:",
    remarkInfo:
      "This question displays random answer options when taking this test.",
    outOf: "out of",
    sendEmail: "Send e-mail",
    addEmail: "Add e-mail",
    close: "Close",
    resultsFor: "Results for:",
    percentage: "Percentage:",
    average: "Average",
  },
  categoryDetailsPage: {
    categoryDetails: "Item details",
    answers: "Answers",
    answer: "Answer",
    addQuestion: "Add Question",
    createItem: {
      create: "Create a new item",
      name: "Name",
      submit: "Submit",
    },
    uploadItem: {
      uploadTitle: "Upload excel with the info",
      uploadLabel: "Upload an excel spreadsheet file:",
      uploadButton: "Upload",
      worksheetName: "Worksheet name",
      worksheetLabel: "Worksheet",
      existingCategories: "Existing categories",
      categoriesLabel: "Categories",
      true: "True",
      false: "False",
      operationType: "Operation type",
      operationLabel: "Operation",
      merge: "Merge",
      replace: "Replace",
      submit: "Submit",
    },
    editQuestion: {
      createQuestionTitle: "Create a new question",
      editQuestionTitle: "Create a new question",
      questionText: "Question text",
      answerText: "Answer text",
      addAnswer: "Add answer",
      save: "Save",
      cancel: "Cancel",
    },
    deleteButton: "Do you want to delete this question?",
    yes: "Yes",
    no: "No",
  },
  professionalDevelopmentPage: {
    title: "Professional Development",
  },
  culturalDevelopmentPage: {
    title: "Cultural Development",
  },
  personalDevelopmentPage: {
    title: "Personal Development",
  },
  editClassification: {
    title: "Edit:",
    nameLabel: "Name",
    search: "Search...",
    save: "Save",
    cancel: "Cancel",
  },
  continueSurvey: {
    title: "The survey is not over",
    continue: "Continue with the survey",
  },
  registration: {
    title: "Your login is",
    firstName: "First Name",
    lastName: "Last Name",
    username: "Username",
    password: "Password",
    confirmPassword: "Confirm password",
    submit: "Submit",
  },
  login: {
    email: "Email",
    password: "Password",
    login: "Login",
    forogtPassword: "Forgot password?",
  },
  resetPassword: {
    title: "Reset your password",
    email: "Email",
    submit: "Reset Password",
  },
};
