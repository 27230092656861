export const Roles = {
  basic: "Basic",
  admin: "Admin",
  owner: "Owner",
  user: "User",
};

export const Routes = {
  home: "/",
  login: "/login",
  reset: "/reset",
  newPassword: "/new-password",
  categories: "/categories",
  createCategory: "/category/create",
  categoryId: "/category/:categoryId",
  categoryIdCreateQuestion: "/category/:categoryId/createquestion",
  organizationIdInvitation: "/organization/:organizationId/invitation",
  createOrganization: "/createorganization",
  createSurvey: "/createsurvey",
  surveyAccessCode: "/survey/:accessCode",
  organization: "/organization",
  invitationInvitationId: "/invitation/:invitationId",
  registerInvitationId: "/register/:invitationId",
  import: "/import",
  organizations: "/organizations",
  organizationsOrgId: "/organizations/:orgId",
  templates: "/templates",
  fantastics: "/fantastics",
  professionalDevelopment: "/professionalDevelopment",
  personalDevelopment: "/personalDevelopment",
  culturalDevelopment: "/culturalDevelopment",
  templatesOrgId: "/templates/:orgId",
  createTemplate: "/createtemplate",
  dashboardAdmin: "/dashboardadmin",
  dashboardAdminOrgId: "/dashboardadmin/:orgId",
  dashboardAllSurveys: "/allsurveys",
  dashboardAllSurveysOrgId: "/allsurveys/:orgId",
  reportResults: "/reportresults/:orgId",
  code: "/code",
  legal: "/legal",
  createsurveymodel: "/createsurveymodel",
  surveymodels: "/surveymodel",
  organizationsurveys: "/organizationsurveys/:organizationId",
};
