import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Category } from "../layout/models/category";
import { CategoryCreate } from "../layout/models/categoryCreate";
import { PagedQuery } from "../layout/models/pagedQuery";

export default class CategoryStore {
  categories: Category[] | null = null;
  totalCount: number = 0;
  pageNumber: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  addCategory = async (category: CategoryCreate) => {
    try {
      const categoryId = await agent.Categories.create(category);
      runInAction(() =>
        //@ts-ignore
        this.categories?.push({ id: categoryId, name: category.name })
      );
    } catch (error) {
      throw error;
    }
  };

  categoryPages = async (query: PagedQuery) => {
    try {
      const categories = await agent.Categories.paged(query);
      runInAction(() => {
        this.categories = categories.data;
        this.totalCount = categories.totalCount;
      });
    } catch (error) {
      throw error;
    }
  };

  setPage(pageNumber: number) {
    this.pageNumber = pageNumber;
  }
}
