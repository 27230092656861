import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Template } from "../layout/models/template";
import { PagedQuery } from "../layout/models/pagedQuery";

export default class TemplateStore {
  templates: Template[] | null = null;
  totalCount: number = 0;
  pageNumber: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getTemplate = async (query: PagedQuery) => {
    try {
      const templates = await agent.Templates.paged(query);
      runInAction(() => {
        this.templates = templates.data;
        this.totalCount = templates.totalCount;
      });
    } catch (error) {
      throw error;
    }
  };

  setPage(pageNumber: number) {
    this.pageNumber = pageNumber;
  }
}
