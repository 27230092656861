import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent";
import { Organization } from "../layout/models/organization";
import { PagedQuery } from "../layout/models/pagedQuery";
export default class OrganizationStore {
    organizations: Organization[] | null = null;
    totalCount: number = 0;

    constructor() {
        makeAutoObservable(this)
    }

    getOrganization = async (query: PagedQuery) => {
        try{
            const organizations = await agent.Organizations.paged(query);
            
            runInAction(() => {
                this.organizations = organizations.data;
                this.totalCount = organizations.totalCount;
            });
        } catch (error) {
            throw error;
        }
    }
}