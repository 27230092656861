import { action, makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { SurveyModel } from "../layout/models/surveyModel";
import { PagedQuery } from "../layout/models/pagedQuery";

export default class SurveyModelStore {
  surveyModels: SurveyModel[] | null = null;
  totalCount: number = 0;
  pageNumber: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getSurveyModel = async (query: PagedQuery) => {
    try {
      const surveyModels = await agent.SurveyModels.paged(query);

      this.setSurveys(surveyModels);
    } catch (error) {
      throw error;
    }
  };

  setSurveys = (surveyModels: { data: any; totalCount: number }) => {
    this.surveyModels = surveyModels.data;
    this.totalCount = surveyModels.totalCount;
  };

  setPage(pageNumber: number) {
    this.pageNumber = pageNumber;
  }
}
