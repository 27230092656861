import React, { useState } from "react";
import { useStore } from "../stores/store";
import Divider from "@material-ui/core/Divider";
import { Box, Typography } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import i18n from "../translation/i18n";

const UserMenuMobile = () => {
  const { userStore } = useStore();
  const [openLanguage, setOpenLanguage] = useState<boolean>(false);

  const handleLanguageDropDown = () => {
    setOpenLanguage(!openLanguage);
  };

  const userMenuStyle = {
    container: {
      backgroundColor: "white",
      marginTop: -25,
    },
    userWelcome: {
      padding: "2rem 0 1rem 0.5rem",
      marginBottom: 5,
      color: "black",
    },
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Box py={2} style={userMenuStyle.container}>
      <Typography variant={"h6"} style={userMenuStyle.userWelcome}>
        Hello, {userStore.user?.given_name}!
      </Typography>
      <Divider />
      <List component="nav" aria-labelledby="user-menu" style={{ padding: 0 }}>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
        </ListItem>

        <Divider />

        <ListItem button onClick={handleLanguageDropDown}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary="Language" />
        </ListItem>

        <Divider />

        <Collapse in={openLanguage} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText
                primary="English"
                onClick={() => changeLanguage("en")}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary="French"
                onClick={() => changeLanguage("fr")}
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => userStore.logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );
};

export default UserMenuMobile;
