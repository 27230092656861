import React, { useState } from "react";
import { useStore } from "../stores/store";
import {
  AppBar,
  Box,
  Paper,
  makeStyles,
  Toolbar,
  Typography,
  Button
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from "react-router-dom";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import i18n from "../translation/i18n";


const useStyles = makeStyles((theme) => ({
  navbar: {
    background: "linear-gradient(45deg, #fb5e00 30%, #ea0453 90%)",
    color: "white",
    padding: 0,
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  flexSpace: {
    justifyContent: "space-between",
  },
  linkHome: {
    color: "white",
    textDecoration: "none",
  },
  userMenu: {
    padding: "1rem",
    position: 'absolute',
    right: 15,
    top: 70,
    width: 250,
    height: 'fit-content',
    backgroundColor: 'f4f5f6',
    zIndex: 999,
    textAlign: 'center'
  },
  userMenuIcon: {
    fontSize: '30px !important',
    color: 'white'
  },
  userMenuName: {
    color: 'white',
    textTransform: 'capitalize'
  }
}));


export default function NavBar({openMenu, setOpenMenu}: any) {
  const classes = useStyles();
  const { userStore } = useStore();
  const [openLanguage, setOpenLanguage] = useState<boolean>(false);
  

  const tabletView = useMediaQuery('(max-width:769px)');
  const mobileView = useMediaQuery('(max-width:427px)');

  const handleUserMenu = () => {
    setOpenMenu(false);
    setOpenLanguage(false)
  };

  const handleLanguageDropDown = () => {
    setOpenLanguage(!openLanguage);
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  }

  return (
    <AppBar className={classes.navbar} position="static">
      <Toolbar className={classes.flexSpace}>
        <Typography
          className={classes.linkHome}
          variant="h6"
          component={Link}
          to="/" >
          ZEBRA
        </Typography>
        {
          !userStore.isLoggedIn &&
          <ClickAwayListener onClickAway={handleUserMenu}>
            <Button onClick={() => setOpenMenu(!openMenu)} style={{color: 'white', fontWeight: 600}}>
              <Typography style={{fontSize: '1.2rem'}}>
                Languages
              </Typography>
            </Button>
          </ClickAwayListener>
        }
        {
          !userStore.isLoggedIn && openMenu &&
          <Paper elevation={3} className={classes.userMenu}>
            <Collapse in={openMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button  onClick={() => changeLanguage('en')}>
                    <ListItemText primary="English" />
                  </ListItem>
                  <ListItem button onClick={() => changeLanguage('fr')}>
                  <ListItemText primary="French"/>
                </ListItem>
              </List>
            </Collapse>
          </Paper>
        }
        {
          userStore.isLoggedIn && !tabletView &&
          <Box display="flex" alignItems="center">
            <Button
              onClick={() => setOpenMenu(!openMenu)}
              startIcon={<AccountCircle className={classes.userMenuIcon}/>} >
              <Typography variant={'h6'} className={classes.userMenuName}>
                {userStore.user?.given_name}
              </Typography>
            </Button>
          </Box>
        }

        { userStore.isLoggedIn && tabletView &&
          <Box display="flex" alignItems="center">
            <IconButton
                aria-label="current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => setOpenMenu(!openMenu)} >
                <MenuIcon />
              </IconButton>
          </Box>
        }

        { userStore.isLoggedIn && openMenu && !mobileView &&
          <ClickAwayListener onClickAway={handleUserMenu}>
            <Paper elevation={3} className={classes.userMenu}>
              <Typography variant={'h6'} style={{marginBottom: 5}}>
                Hello, {userStore.user?.given_name}!
              </Typography>
              <Divider/>
              <List component="nav" aria-labelledby="user-menu">
                <ListItem button onClick={()=>{}}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                </ListItem>

                <ListItem button onClick={handleLanguageDropDown}>
                  <ListItemIcon>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Language" />
                </ListItem>

                <Collapse in={openLanguage} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button  onClick={() => changeLanguage('en')}>
                      <ListItemText primary="English" />
                    </ListItem>
                    <ListItem button onClick={() => changeLanguage('fr')}>
                      <ListItemText primary="French"/>
                    </ListItem>
                  </List>
                </Collapse>
                
                <ListItem button onClick={() => userStore.logout()}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Paper>
          </ClickAwayListener>
        }
      </Toolbar>
    </AppBar>
  );
}
