export const TRANSLATIONS_FR = {
  description: {
    welcomeStory: "Connectez-vous et commencez à utiliser Zebra.",
  },
  home: {
    about: `ZEBRA est un outil permettant de prédire avec un taux de réussite de plus de 95 % le fonctionnement d’un individu dans différentes situations professionnelles.
        Il est construit à partir de la mesure d’ITEMS innovants et l’analyse de données croisées jamais utilisées jusqu’alors.
        Nous le mettons à disposition des entreprises soucieuses de sécuriser leurs opérations de gestion des talents mais aussi de particuliers désireux de mieux se connaitre pour s’orienter efficacement.`,
    corporate: `Corporate`,
    particular: "Particulier",
  },
  dashboard: {
    templates: {
      title: "Dimensions",
      description:
        "Les dimensions regroupent un ensemble d’items mettant en évidence un fonctionnement global sur une thématique spécifique. Une dimension regroupe plusieurs items",
    },
    categories: {
      title: "Items",
      description:
        "Un item est un thème d’évaluation donné. Chaque item fait partie d’une dimension",
    },
    organizations: {
      title: "Licences Clients",
      name: "Nom",
      description: "Gestion des accès pour des organisations",
      createOrganization: {
        title: "Créer une organisation",
        nameLabel: "Nom de l'organisation",
        surveyLimit: "Limite des tests",
        date: "Date de début de la licence",
        endDate: "Date de fin de la licence",
        submit: "Envoi",
        inviteButton: "Inviter l'administrateur",
        createStep: "Créer l'organisation",
        inviteStep: "Envoyer l'invitation à l'administrateur",
      },
      editOrganization: {
        title: "Modifier l’organisation:",
        titleLabel: "Titre",
        surveyLimit: "Limite des tests",
        date: "Date de début de la licence",
        endDate: "Date de fin de la licence",
        search: "Rechercher...",
        save: "Sauvegarder",
        cancel: "Annuler",
      },
      deleteButton: "Voulez-vous supprimer cette organisation?",
      yes: "Oui",
      no: "Non",
    },
    myOrganization: {
      title: "Mon Organisation",
      description: "Ma licence et les membres de mon organisation",
    },
    mySurveys: {
      title: "Mes Questionnaires",
      description: "Tests créés par mon organisation",
    },
    allSurveys: {
      title: "Tous les questionnaires",
      description: "Tests de toutes les organisations du système",
    },
    allSurveyModels: {
      title: "Tous les modèles de questionnaires",
      description: "Tests créés par l’Administrateur",
    },
    fantastics: {
      title: "Les Quatre Fantastiques",
      description: "Types de personnalité",
    },
    professionalDevelopment: {
      title: "Épanouissement Professionnel",
      description: "",
    },
    culturalDevelopment: {
      title: "Épanouissement Culturel",
      description: "",
    },
    personalDevelopment: {
      title: "Épanouissement Personnel",
      description: "",
    },
  },
  survey: {
    startMessage:
      "Choisissez la réponse qui spontanément vous correspond le plus. A défaut, choisissez celle qui s’éloigne le moins de vous. Bon questionnaire!",
    startMessageTitle: "Avant le questionnaire",
    endMessage:
      "Merci d’avoir participé à ce questionnaire. Nous revenons vers vous très vite pour vous faire part des résultats!",
    endMessageTitle: "Fin de l'enquête",
    endMessageDescription: "Vous avez atteint la fin.",
  },
  surveyByCodePage: {
    inputMessage: "Entrez votre code d’accès pour démarrer le questionnaire",
  },
  templatesPage: {
    pageTitle: "Dimensions",
    columnTitle: "Dimension",
    createButton: "Nouvelle dimension",
    createTemplate: {
      title: "Créer une nouvelle dimension",
      templateName: "Nom de la dimension",
      templateLabel: "Dimension",
      choices: "Selections",
      selected: "selectés",
      submit: "Envoi",
    },
    editTemplate: {
      title: "Modifier la dimension:",
      nameLabel: "Nom",
      search: "Rechercher...",
      save: "Sauvegarder",
      cancel: "Annuler",
    },
    deleteButton: "Voulez-vous supprimer cette dimension?",
    yes: "Oui",
    no: "Non",
  },
  fantasticsPage: {
    title: "Les Quatre Fantastiques",
    columnTitle: "Nom",
  },
  categoriesPage: {
    pageTitle: "Items",
    columnTitle: "Item",
    createButton: "Télécharger des items",
    editButton: "Créer un item",
    deleteButton: "Voulez-vous supprimer cet item?",
    yes: "Oui",
    no: "Non",
  },
  myOrganizationPage: {
    pageTitle: "Mon organisation",
    licensesActive: "Licence active jusqu’au:",
    surveyLimit: "Questionnaires restants:",
    columnTitle: "Noms",
    addUserButton: "Ajouter utilisateur",
    inviteAdmin: {
      title: "Envoyer une invitation d’administrateur",
      createStep: "Créer l'organisation",
      inviteStep: "Envoyer l'invitation à l'administrateur",
      emailLabel: "E-mail",
      send: "Envoi",
    },
  },
  mySurveysPage: {
    pageTitle: "Mes Questionnaires",
    columnTitle: "Titre",
    participantColumn: "Participant",
    sentColumn: "Date d'envoi",
    completedColumn: "Date Realisation",
    linkColumn: "Lien",
    unfinished: "Inachevé",
    statusColumn: "Statut",
    completedStatus: "Finalisé",
    inprogressStatus: "En cours",
    sentStatus: "Soumis",
    createButton: "Nouveau Questionnaire",
    deleteButton: "Voulez-vous supprimer ce questionnaire?",
    filtersButton: "Filtres",
    ascending: "Ascendant",
    descending: "Descendant",
    none: "Aucun",
    applyFilters: "Appliquer des filtres",
    clearFilters: "Supprimer les filtres",
    close: "Fermer",
    yes: "Oui",
    no: "Non",
    participantName: "Nom du participant",
    finishedDate: "Date de fin",
    createSurvey: {
      title: "Créer et envoyer un questionnaire",
      surveyTitle: "Titre du questionnaire",
      participantName: "Nom du participant",
      participantEmail: "Courriel du participant",
      search: "Rechercher...",
      submit: "Envoi",
    },
  },
  allSurveysPage: {
    pageTitle: "Tous les Questionnaires",
    columnTitle: "Titre",
    participantColumn: "Participant",
    sentColumn: "Date d'envoi",
    completedColumn: "Date Realisation",
    linkColumn: "Lien",
    organisationColumn: "Organisation",
    statusColumn: "Statut",
    completedStatus: "Finalisé",
    inprogressStatus: "En cours",
    sentStatus: "Soumis",
  },
  organizationsPage: {
    pageTitle: "Organisations",
    columnTitle: "Nom de l'organisation",
    surveyLeftColumn: "Questionnaires restants",
    surveysCreatedColumn: "Questionnaires créés",
    licenseEndDateColumn: "Date de fin de licence",
    createButton: "Nouvelle invitation",
  },
  global: {
    nextButton: "Suivante",
    continueButton: "Continuer",
  },
  surveyModelPage: {
    pageTitle: "Modèles de questionnaires",
    columnTitle: "Nom du modèle de questionnaire",
    createButton: "Nouveau modèle de questionnaire",
    createSurveyModel: {
      title: "Créer un modèle de questionnaire",
      surveyTitle: "Titre du questionnaire",
      search: "Rechercher...",
      submit: "Envoi",
    },
    editSurveyModel: {
      title: "Modifier le modèle de questionnaire:",
      nameLabel: "Nom",
      search: "Rechercher...",
      save: "Sauvegarder",
      cancel: "Annuler",
    },
    deleteButton: "Voulez-vous supprimer ce modèle de questionnaire?",
    yes: "Oui",
    no: "Non",
  },
  reportPage: {
    key: "Clé:",
    info: "Une coche (si juste) ou une croix (si erronée) apparaîtra à côté des réponses choisies.",
    correct: "Répondue correctement",
    incorrect: "Répondue incorrectement",
    missed: "Option de bons choix multiples manquée",
    advice: "Choisissez la reponsé qui vous qualifie le mieux",
    goodAnswer: "Bonne responsé:",
    yourAnswer: "Vous avez choisi:",
    remark: "Remarque:",
    remarkInfo:
      "Cette question affiche des options de réponses dans le désordre lors de la participation à ce test.",
    outOf: "sur",
    sendEmail: "Envoyer par e-mail",
    addEmail: "Ajouter e-mail",
    close: "Fermer",
    resultsFor: "Résultats pour:",
    percentage: "Pourcentage:",
    average: "Moyenne",
  },
  categoryDetailsPage: {
    categoryDetails: "Détails de l'item",
    answers: "Réponses",
    answer: "Réponse",
    addQuestion: "Ajouter une question",
    createItem: {
      create: "Créer un nouvel item",
      name: "Nom",
      submit: "Créer",
    },
    uploadItem: {
      uploadTitle: "Télécharger excel avec les informations",
      uploadLabel: "Télécharger un fichier excel",
      uploadButton: "Télécharger",
      worksheetName: "Nom du fichier",
      worksheetLabel: "Fichier",
      existingCategories: "Items existents",
      categoriesLabel: "Items",
      true: "Vrai",
      false: "Faux",
      operationType: "Type de l'opération",
      operationLabel: "Opération",
      merge: "Fusionner",
      replace: "Remplacer",
      submit: "Envoi",
    },
    editQuestion: {
      createQuestionTitle: "Créer une nouvelle question",
      editQuestionTitle: "Modifier la question",
      questionText: "Texte de la question",
      answerText: "Texte de la réponse",
      addAnswer: "Ajouter une réponse",
      save: "Sauvegarder",
      cancel: "Annuler",
    },
    deleteButton: "Voulez-vous supprimer cette question?",
    yes: "Oui",
    no: "Non",
  },
  professionalDevelopmentPage: {
    title: "Epanouissement Professionnel",
  },
  culturalDevelopmentPage: {
    title: "Epanouissement Culturel",
  },
  personalDevelopmentPage: {
    title: "Epanouissement Personnel",
  },
  editClassification: {
    title: "Modifier:",
    nameLabel: "Nom",
    search: "Rechercher...",
    save: "Sauvegarder",
    cancel: "Annuler",
  },
  continueSurvey: {
    title: "Le questionnaire n'est pas fini",
    continue: "Continuer le questionnaire",
  },
  registration: {
    title: "Votre identifiant est",
    firstName: "Prénom",
    lastName: "Nom",
    username: "Identifiant",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    submit: "S’inscrire",
  },
  login: {
    email: "E-mail",
    password: "Mot de passe",
    login: "Se connecter",
    forogtPassword: "Mot de passe oublié?",
  },
  resetPassword: {
    title: "Réinitialisez votre mot de passe",
    email: "E-mail",
    submit: "Réinitialiser le mot de passe",
  },
};
