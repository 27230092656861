import { Button, makeStyles } from "@material-ui/core";
import react, { useState } from "react";
import { useStore } from "../../stores/store";
import "./cookieWarningStyle.css";

const useStyles = makeStyles((theme) => ({
  acceptCookiesButton: {
    backgroundColor: "#ffd100",
    "&:hover": {
      backgroundColor: "#e6bc00",
    },
  },
}));

const CookieWarning = () => {
  const styles = useStyles();
  const [cookieBoxStyle, setCookieBoxStyle] = useState("cookieBox");

  var temp = false;
  if (window.localStorage.getItem("consent")) {
    var check = JSON.parse(window.localStorage.getItem("consent")!);
    if (check.gdprApplies) {
      temp = check.gdprApplies;
    }
  } else {
    temp = false;
  }

  const [accepted, setAccepted] = useState(temp);

  const handleCookiesAccepted = async () => {
    window.localStorage.setItem("consent", `{"gdprApplies": true}`);
    setCookieBoxStyle("cookieBox-hidden");
    setTimeout(() => {
      setAccepted(true);
    }, 300);
  };

  const path = window.location.origin + "/legal";

  return (
    <>
      {!accepted && (
        <div className={cookieBoxStyle}>
          <p>
            Pour améliorer votre expérience, nous utilisons des cookies. Pour en
            savoir plus sur les cookies, cliquez <a href={path}>ici</a>. Pour
            continuer à utiliser l'application, vous devez accepter
            l'utilisation des cookies.
          </p>
          <Button
            onClick={handleCookiesAccepted}
            className={styles.acceptCookiesButton}
          >
            Accept cookies
          </Button>
        </div>
      )}
    </>
  );
};

export default CookieWarning;
